<template>
  <div id="siteMap" :style="{width: width, height: height}"></div>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Point} from "ol/geom";
import {Feature} from "ol";
import {fromLonLat} from "ol/proj";
import {Stroke, Style, Fill, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";
import {FullScreen, defaults as defaultControls} from 'ol/control';
export default {
  name: "SiteMap",
  props: {
    width: String,
    height: String,
    latitude: String,
    longitude: String,
    label: String
  },
  data(){
    return {
      map: null
    }
  },
  mounted(){
    setTimeout(() => {
      this.initMap();
    }, 2000);
  },
  methods: {
    initMap(){
      this.map = new Map({
        target: 'siteMap',
        controls: defaultControls().extend([new FullScreen()]),
        layers: [
          new TileLayer({
            source: new XYZ({
              url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
            })
          })
        ],
        view: new View({
          center: fromLonLat([this.longitude, this.latitude]),
          zoom: 6
        })
      });
      this.vectorSource = new VectorSource({});

      this.markersLayer = new VectorLayer({
        source: this.vectorSource,
      });

      this.map.addLayer(this.markersLayer);

      this.addMarker(this.latitude, this.longitude, this.label)
    },
    addMarker(lat, long, label = ""){
      let point1 = new Point(
          fromLonLat([long, lat])
      );
      let newMarker = new Feature({
        geometry: point1,
        name: "siteMarker"
      })

      let color = 'red';

      let style = new Style({
        image: new CircleStyle({
          radius: 15,
          fill: new Fill({color: color}),
          stroke: new Stroke({
            color: 'white', width: 2
          })
        }),
        text: new Text({
          text: label,
          scale: 1.3,
          fill: new Fill({
            color: '#fff'
          }),
          stroke: new Stroke({
            color: '0',
            width: 3
          }),
          offsetY: 20
        })
      });

      newMarker.setStyle(style);

      this.vectorSource.addFeature(newMarker);
    }
  }

}
</script>

<style scoped>

</style>