<template>
    <div class="card">
        <div class="chat-wrapper" style="height: 340px">
          <div class="chat-content" style="top: 0px; margin-left: 0px; height: 270px;">
            <div v-for="note in notes" :key="note.id" :class="{'chat-content-leftside': this.user.id !== note.user.id, 'chat-content-rightside': this.user.id === note.user.id}">
              <div class="d-flex">
                <div class="flex-grow-1 ms-2">
                  <p class="mb-0 chat-time" :class="{'text-end': this.user.id === note.user.id}">{{note.user.fname}} {{note.user.lname}}, <V-Time :datetime="note.created_at" format='dd/MM/yyyy HH:mm:ss' /> <span class="text-danger" style="cursor: pointer;" @click="deleteNote(note)">delete</span></p>
                  <p :class="{'chat-left-msg': this.user.id !== note.user.id, 'chat-right-msg': this.user.id === note.user.id}">{{note.note}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-footer d-flex align-items-center" style="left: 0px;">
            <div class="flex-grow-1 pe-2">
              <div class="input-group">
                <input type="text" class="form-control" v-model="note" placeholder="Type a note" v-on:keyup.enter="createNote">
              </div>
            </div>
          </div>
          <!--start chat overlay-->
          <div class="overlay chat-toggle-btn-mobile"></div>
          <!--end chat overlay-->
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import VTime from '../../../components/Time.vue';
import { mapGetters } from 'vuex';
import {DateTime} from 'luxon';

export default {
    name: 'SiteNotes',
    components: {
        VTime
    },
    props: {
        site_id: {
            type: [Number, String],
            required: true
        }
    },
    data(){
        return {
            notes: []
        }
    },
    mounted(){
        this.getNotes();
    },
    methods: {
        getNotes(){
            axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site_id}/notes`)
            .then(response => {
                this.notes = response.data.notes;
                this.$emit('notecount', this.notes.length);
            })
            .catch(error => {
                console.error(error);
            });
        },
        createNote(){
            axios.put(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site_id}/notes`, {
                note: this.note
            })
            .then(() => {
                this.notes.push({
                user: {
                    fname: this.user.fname,
                    lname: this.user.lname,
                    id: this.user.id
                },
                created_at: DateTime.local().toSeconds(),
                note: this.note
                });
                this.note = "";
            })
            .catch(error => {
                this.$error("Failed to add note", error);
            })
        },
        deleteNote(note){
            axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site_id}/notes`, {
                data: {
                    note_id: note.id
                }
            })
            .then(() => {
                this.notes = this.notes.filter(item => item.id !== note.id);
                this.$emit('notecount', this.notes.length);
            })
            .catch(error => {
                this.$error("Failed to delete note", error);
            })
        }
    },
    computed: {
        ...mapGetters({
			user: 'auth/user',
            token: 'auth/token'
		}),
    }
}
</script>