<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div v-if="loading" class="text-center">
            <div  class="spinner-grow" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <div v-else>
            <table class="table mb-0 table-hover">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th style="width: 1%;">Encrypt?</th>
                  <th style="width: 1%;">Reminder?</th>
                  <th style="width: 1%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="at in attributes" :key="'attribute-' + at.id">
                  <td><textarea class="form-control form-control-sm" v-model="at.key"></textarea></td>
                  <td><textarea class="form-control form-control-sm" v-model="at.value"></textarea></td>
                  <td><input tabindex="-1" class="" type="checkbox" v-model="at.encrypt"></td>
                  <td><input tabindex="-1" class="" type="checkbox" v-model="at.reminder"></td>
                  <td><i class='bx bx-save text-success font-24' @click="saveAttribute(at)" style="cursor:pointer;"></i> <i class='bx bx-trash text-danger font-24' @click="deleteAttribute(at)" style="cursor:pointer;"></i></td>
                </tr>
              </tbody>
            </table>

            <button class="btn btn-primary float-end mt-2" @click="addAttribute">Add Attribute</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';
export default {
  name: "SiteAttributes",
  props: {
    site: Object
  },
  data(){
    return {
      loading: true,
      attributes: []
    }
  },
  mounted(){
    this.loadAttributes()
  },
  methods: {
    loadAttributes(){
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/attributes`)
      .then(response => {
        this.attributes = response.data.attributes;
        this.attributes.forEach((a) => {
          a.uuid = uuidv4();
        })
      })
      .catch(error => {
        this.$error("Failed to load attributes", error);
      })
      .finally(() => {
        this.loading = false;
      })
    },
    addAttribute(){
      this.attributes.push({uuid: uuidv4(), key: '', value: '', encrypt: false, reminder: false})
    },
    deleteAttribute(attribute){
      if(attribute.id){

        axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/attributes/${attribute.id}`)
        .then(response => {
          if(response.data.success){
            this.$success("Deleted attribute.");
            this.attributes = this.attributes.filter((a) => {
              return a.uuid !== attribute.uuid;
            });
          }
        })
        .catch(error => {
          this.$error("Failed to delete attribute", error);
        });

      }else{
        this.attributes = this.attributes.filter((a) => {
          return a.uuid !== attribute.uuid;
        });
      }
    },
    saveAttribute(attribute){
      if(attribute.id){
        axios.patch(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/attributes/${attribute.id}`, attribute)
            .then(response => {
              if(response.data.success){
                this.$success("Saved changes to attribute");
              }
            })
            .catch(error => {
              this.$error("Failed to save changes to attribute", error);
            })
      }else{
        axios.put(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/attributes`, attribute)
          .then(response => {
            if(response.data.success){
              this.$success("Saved changes to attribute")
              attribute.id = response.data.id;
            }
          })
          .catch(error => {
            this.$error("Failed to save changes to attribute", error);
          })
      }

    }
  }
}
</script>

<style scoped>

</style>