<template>
  <div class="row">
    <div class="col-12 col-lg-8 col-xl-8 d-flex">
      <div class="card radius-10 w-100">
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div>
              <h6 class="mb-0">Site Uptime</h6>
            </div>
            <!--              <div class="font-22 ms-auto text-white"><i class="bx bx-dots-horizontal-rounded"></i>-->
            <!--              </div>-->
          </div>
          <div class="d-flex align-items-center ms-auto font-13 gap-2 my-3">
            <span class="border px-1 rounded cursor-pointer"><i class="bx bxs-circle me-1" style="color: lightgreen;"></i>Health</span>
          </div>
          <div class="chart-container-1"><div class="chartjs-size-monitor" style="position: absolute; inset: 0px; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -1;"><div class="chartjs-size-monitor-expand" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:1000000px;height:1000000px;left:0;top:0"></div></div><div class="chartjs-size-monitor-shrink" style="position:absolute;left:0;top:0;right:0;bottom:0;overflow:hidden;pointer-events:none;visibility:hidden;z-index:-1;"><div style="position:absolute;width:200%;height:200%;left:0; top:0"></div></div></div>
            <canvas id="siteStatsChart" width="895" height="260" style="display: block; width: 895px; height: 260px;" class="chartjs-render-monitor"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global Chart */
import axios from 'axios';
import {DateTime} from 'luxon';
export default {
  name: "SiteStats",
  props: {
    site_id: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      uptime: [],
      chart: null
    }
  },
  mounted(){
    this.loadUptime();
  },
  methods: {
    loadUptime(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site_id}/uptime`)
      .then(response => {
        this.uptime = response.data.uptime;
        var ctx = document.getElementById('siteStatsChart').getContext('2d');
        let labels = [];
        let health = [];
        this.uptime.forEach((e) => {
          e.label = DateTime.fromSeconds(e.day).toFormat('dd/MM/yyyy') + ' ' + e.hour;
        });
        this.uptime = this.uptime.sort((a,b) => {
          let Ats = DateTime.fromFormat(a.label, 'dd/MM/yyyy H').toSeconds();
          let Bts = DateTime.fromFormat(b.label, 'dd/MM/yyyy H').toSeconds();
          if(Ats < Bts){
            return -1;
          }
          if(Ats > Bts){
            return 1;
          }
          return 0;
        })
        this.uptime.forEach((e) => {
          if(!labels.includes(e.day + ' ' + e.hour)){
            labels.push(e.label);
          }
          health.push(parseFloat((e.online_pings / (e.offline_pings + e.online_pings)) * 100).toFixed(2));
        })
        this.chart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [{
              label: 'Health',
              data: health,
              backgroundColor: 'rgba(255, 255, 255, 0.25)',
              borderColor: "lightgreen",
              pointRadius :"0",
              borderWidth: 3
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
              labels: {
                fontColor: '#ddd',
                boxWidth:40
              }
            },
            tooltips: {
              displayColors:false
            },
            scales: {
              xAxes: [{
                ticks: {
                  beginAtZero:true,
                  fontColor: '#ddd'
                },
                // gridLines: {
                //   display: true ,
                //   color: "rgba(221, 221, 221, 0.08)"
                // },
              }],
              yAxes: [{
                ticks: {
                  beginAtZero:true,
                  fontColor: '#ddd'
                },
                // gridLines: {
                //   display: true ,
                //   color: "rgba(221, 221, 221, 0.08)"
                // },
              }]
            }

          }
        });
      })
      .catch(error => {
        this.$error("Failed to load stats", error);
      })
    }
  }
}
</script>

<style scoped>

</style>