<template>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div v-if="loading" class="text-center">
            <div  class="spinner-grow" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <div v-else>

            <div class="row">

              <table class="table mb-0 table-hover">
                <thead>
                  <tr>
                    <th>Contract Type</th>
                    <th>Maintenance Type</th>
                    <th>Frequency</th>
                    <th>Renewal Date</th>
                    <th>Reminder</th>
                    <th>Call Out Timeframe</th>
                    <th>Next Due</th>
                    <th>Current Job</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="schedule in schedules" :key="'schedule-' + schedule.id">
                    <td>
                      <select class="form-select" id="contract_type" v-model="schedule.contract_type">
                        <option value="Access Control">Access Control</option>
                        <option value="CCTV">CCTV</option>
                        <option value="Other">Other</option>
                      </select>
                    </td>
                    <td>
                      <select class="form-select" id="maintenance_type" v-model="schedule.type">
                        <option value="Full Comprehensive">Fully Comprehensive</option>
                        <option value="Labour Only">Labour Only</option>
                      </select>
                    </td>
                    <td>
                      <select class="form-select" id="maintenance_frequency" v-model="schedule.frequency">
                        <option value="30">Monthly</option>
                        <option value="90">Quarterly</option>
                        <option value="180">Bi-Annually</option>
                        <option value="365">Annually</option>
                      </select>
                    </td>
                    <td>
                      <input type="date" class="form-control" id="maintenance_renewal_date" v-model="schedule.renewal_date">
                    </td>
                    <td>
                      <input type="checkbox" class="form-check-input" id="maintenance_reminder" v-model="schedule.reminder">
                    </td>
                    <td>
                      <select class="form-select" id="maintenance_call_out_timeframe" v-model="schedule.call_out_timeframe">
                        <option value="24 Hours">24 Hours</option>
                        <option value="48 Hours">48 Hours</option>
                        <option value="72 Hours">72 Hours</option>
                        <option value="1 Week">1 Week</option>
                        <option value="2 Weeks">2 Weeks</option>
                        <option value="1 Month">1 Month</option>
                      </select>
                    </td>
                    <td>
                      <input type="date" class="form-control" id="maintenance_next_due" v-model="schedule.next_due">
                    </td>
                    <td>
                      <a class="d-block" style="cursor: pointer;" v-if="schedule.current_job !== null && schedule.current_job !== undefined" @click="openCurrentJob(schedule)"><span class="link"><i class='bx bx-link'></i> Job #{{schedule.current_job}}</span></a>
                      <span v-else>N/A</span>
                    </td>
                    <td>
                      <button class="btn btn-primary" style="margin-right: 10px;" @click="saveMaintenance(schedule)">Save</button>
                      <button class="btn btn-danger" @click="deleteMaintenance(schedule)">Delete</button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <select class="form-select" id="contract_type" v-model="maintenance.contract_type">
                        <option value="Access Control">Access Control</option>
                        <option value="CCTV">CCTV</option>
                        <option value="Other">Other</option>
                      </select>
                    </td>
                    <td>
                      <select class="form-select" id="maintenance_type" v-model="maintenance.type">
                        <option value="Full Comprehensive">Fully Comprehensive</option>
                        <option value="Labour Only">Labour Only</option>
                      </select>
                    </td>
                    <td>
                      <select class="form-select" id="maintenance_frequency" v-model="maintenance.frequency">
                        <option value="30">Monthly</option>
                        <option value="90">Quarterly</option>
                        <option value="180">Bi-Annually</option>
                        <option value="365">Annually</option>
                      </select>
                    </td>
                    <td>
                      <input type="date" class="form-control" id="maintenance_renewal_date" v-model="maintenance.renewal_date">
                    </td>
                    <td>
                      <input type="checkbox" class="form-check-input" id="maintenance_reminder" v-model="maintenance.reminder">
                    </td>
                    <td>
                      <select class="form-select" id="maintenance_call_out_timeframe" v-model="maintenance.call_out_timeframe">
                        <option value="24 Hours">24 Hours</option>
                        <option value="48 Hours">48 Hours</option>
                        <option value="72 Hours">72 Hours</option>
                        <option value="1 Week">1 Week</option>
                        <option value="2 Weeks">2 Weeks</option>
                        <option value="1 Month">1 Month</option>
                      </select>
                    </td>
                    <td>
                      <input type="date" class="form-control" id="maintenance_next_due" v-model="maintenance.next_due">
                    </td>
                    <td></td>
                    <td>
                      <button class="btn btn-primary" @click="saveMaintenance(maintenance)">Save</button>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            
            <div v-if="false" class="row">
              <div class="col-6">

                <div class="form-group">
                  <label for="contract_type">Contract Type <span class="text-danger">*</span></label>
                  <select class="form-select" id="contract_type" v-model="maintenance.contract_type">
                    <option value="Access Control">Access Control</option>
                    <option value="CCTV">CCTV</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div class="form-group mt-2">
                  <label for="maintenance_type">Maintenance Type <span class="text-danger">*</span></label>
                  <select class="form-select" id="maintenance_type" v-model="maintenance.type">
                    <option value="Full Comprehensive">Fully Comprehensive</option>
                    <option value="Labour Only">Labour Only</option>
                  </select>
                </div>

                <div class="form-group mt-2">
                  <label for="maintenance_frequency">Maintenance Frequency <span class="text-danger">*</span></label>
                  <select class="form-select" id="maintenance_frequency" v-model="maintenance.frequency">
                    <option value="30">Monthly</option>
                    <option value="90">Quarterly</option>
                    <option value="180">Bi-Annually</option>
                    <option value="365">Annually</option>
                  </select>
                </div>

                <div class="form-group mt-2">
                  <label for="maintenance_renewal_date">Renewal Date <span class="text-danger">*</span></label>
                  <input type="date" class="form-control" id="maintenance_renewal_date" v-model="maintenance.renewal_date">
                </div>

                <div class="form-group mt-2">
                  <label for="maintenance_reminder">Renewal Reminder</label>
                  <input type="checkbox" class="form-check-input" id="maintenance_reminder" v-model="maintenance.reminder">
                </div>

                <div class="form-group mt-2">
                  <label for="maintenance_call_out_timeframe">Call Out Timeframe</label>
                  <select class="form-select" id="maintenance_call_out_timeframe" v-model="maintenance.call_out_timeframe">
                    <option value="24 Hours">24 Hours</option>
                    <option value="48 Hours">48 Hours</option>
                    <option value="72 Hours">72 Hours</option>
                    <option value="1 Week">1 Week</option>
                    <option value="2 Weeks">2 Weeks</option>
                    <option value="1 Month">1 Month</option>
                  </select>
                </div>

                <div class="form-group mt-2">
                  <label for="maintenance_next_due">Next Due</label>
                  <input type="date" class="form-control" id="maintenance_next_due" v-model="maintenance.next_due">
                  <span style="font-size: 12px; color: rgba(255,255,255,0.6)">This will be automatically calculated based on the renewal date and frequency. Otherwise, you can manually override.</span>
                </div>

                <button class="btn btn-primary mt-4 float-end" @click="saveMaintenance">Save Maintenance</button>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
export default {
  name: "SiteMaintenance",
  props: {
    site: Object
  },
  data() {
    return {
      loading: true,
      maintenance: {
        frequency: '',
        type: '',
        renewal_date: null,
        reminder: false,
        call_out_timeframe: '',
        next_due: null,
        contract_type: ''
      },
      schedules: []
    };
  },
  mounted(){
    this.getMaintenance();
  },
  methods: {
    getMaintenance(){
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/maintenance/schedule`)
      .then((response) => {
        response.data.schedule.forEach((s) => {
          s.renewal_date = s.renewal_date ? s.renewal_date.split('T')[0] : null;
          s.next_due = s.next_due ? s.next_due.split('T')[0] : null;
          s.reminder = s.reminder === 1;
        })
        this.schedules = response.data.schedule;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    saveMaintenance(maintenance){
      if(!maintenance.id){
        axios.post(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/maintenance/schedule`, maintenance)
        .then(() => {
          this.$success("Maintenance schedule saved");
          this.getMaintenance();
          this.maintenance = {
            frequency: '',
            type: '',
            renewal_date: null,
            reminder: false,
            call_out_timeframe: '',
            next_due: null,
            contract_type: ''
          };
        })
        .catch((error) => {
          this.$error("Failed to save maintenance schedule", error);
        });
      } else {
        axios.patch(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/maintenance/schedule`, maintenance)
        .then(() => {
          this.$success("Maintenance schedule saved");
          this.getMaintenance();
        })
        .catch((error) => {
          this.$error("Failed to save maintenance schedule", error);
        });
      }
    },
    deleteMaintenance(maintenance){
      if(maintenance.id){
        axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/maintenance/schedule`, {
          data: {
            id: maintenance.id
          }
        })
        .then(() => {
          this.$success("Maintenance schedule deleted");
          this.getMaintenance();
        })
        .catch((error) => {
          this.$error("Failed to delete maintenance schedule", error);
        });
      }
    },
    openCurrentJob(schedule){
      window.open(`/p/jobs/${schedule.current_job}`, '_blank').focus()
    }
  }
}
</script>

<style scoped>
.link:hover {
  border-bottom: 1px solid white;
}
</style>