<template>
  <div>
    <div class="modal fade" :id="id" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Job</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img v-if="showSteve" src="/assets/images/SteveBuzz.png" style="z-index: 100000; position: absolute; right: -400px; top: -20px;">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <!-- <div class="col-md-12 mb-2">
                <label for="jobSite" class="form-label">Site <span class="text-danger">*</span></label>
                <select-2 v-model="job.site_id" :value="job.site_id" class="single-select" :options="sites">
                  <option selected disabled>Select a Site</option>
                </select-2>
              </div> -->
              <div class="col-md-12 mb-2">
                <label for="newJobSite" class="form-label">Site <span class="text-danger">*</span></label>
                <Multiselect @change="loadSiteBrands" v-model="newJob.site_id" id="newJobSite" valueProp="id" label="name" :searchable="true" :options="sites"></Multiselect>
              </div>
              <div v-show="existingJobs.length > 0" class="col-md-12 mb-2">
                <span class="text-warning cursor-pointer" @click="openExistingJobs"><i class='bx bxs-flag-alt'></i> {{existingJobs.length}} open jobs for this site!</span>
              </div>
              <div class="col-md-12 mb-2">
                <button class="btn btn-outline-success float-end" @click="openNewSite">New Site</button>
              </div>
              <div v-if="siteBrands.length > 0" class="col-md-12 mb-2">
                <label for="newJobSite" class="form-label">Brand</label>
                <Multiselect v-model="newJob.brand_id" id="newJobBrand" valueProp="id" label="name" :searchable="true" :options="siteBrands"></Multiselect>
              </div>
              <div class="col-md-12 mb-2">
                <label for="jobDescription" class="form-label">Description <span class="text-danger">*</span></label>
                <textarea class="form-control" id="jobDescription" v-model="newJob.description"></textarea>
              </div>
              <div class="col-md-12 mb-2">
                <label for="jobType" class="form-label">Type <span class="text-danger">*</span></label>
                <select v-model="newJob.type" id="jobType" :value="newJob.type" class="form-control form-select">
                  <option value="Installation">Installation</option>
                  <option value="Service">Service</option>
                  <option value="Maintenance">Maintenance</option>
                  <option value="Repair">Repair</option>
                  <option value="Investigation">Investigation</option>
                  <option value="Remote">Remote</option>
                  <option value="Delivery">Delivery</option>
                  <option value="Phone / Email">Phone / Email</option>
                  <option value="Snagging">Snagging</option>
                  <option value="Planning">Planning</option>
                  <option value="Survey">Survey</option>
                </select>
              </div>
              <div class="col-md-12 mb-2">
                <label for="jobReportedBy" class="form-label">Reported By <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="jobReportedBy" v-model="newJob.reported_by">
              </div>
              <div class="col-md-12 mb-2">
                <label for="jobQuoteNumber" class="form-label">Quote Number</label>
                <input type="text" class="form-control" id="jobQuoteNumber" v-model="newJob.quote_number">
              </div>
              <div class="col-md-12 mb-2">
                <p>Assigned Users <i class='bx bx-edit-alt' style="cursor: pointer;"  data-bs-toggle="modal" :data-bs-target="'#manageNewJobUsersModal' + id" @click="focusUserFilter"></i></p>

                <div v-for="user of users" :key="'user-' + user.id" class="chip chip-lg bg-primary text-white">
                  {{user.user.fname}} {{user.user.lname}} <span class="closebtn" @click="removeUser(user)">×</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id='"closeCreateJobModal" + id'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createJob" text="Save changes"></submit-button>
          </div>
        </div>
      </div>

    </div>

    <div class="modal fade" :id="'existingJobsModal' + id" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Existing Jobs</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">

              <table class="table">
                <thead>
                  <tr>
                    <th>JR #</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th style="min-width: 100px;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="job in existingJobs" :key="'ejob-' + job.id">
                    <td>{{job.id}}</td>
                    <td>{{job.description}}</td>
                    <td>
                      <div v-if="job.status == 1" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-success"></i>COMPLETE</div>
                      <div v-else-if="job.status == 2" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-warning"></i>ASSIGNED</div>
                      <div v-else class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-danger"></i>TBA</div>

                      <div v-if="job.status == 1 && (job.not_completed_notes || '').length > 10" class="badge rounded-pill bg-light p-2 text-uppercase px-3 ms-2"><i class="bx bxs-circle me-1 text-warning"></i>FURTHER WORK</div>
                    </td>
                    <td style="min-width: 100px;"><button class="btn btn-sm btn-outline-primary" @click="goTo(job)">Go To</button></td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeExistingJobs" :id='"closeExistingJobModal" + id'>Close</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" :id="'manageNewJobUsersModal' + id" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Assign Job to Users</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="max-height: 500px;">
            <div class="row">

              <input class="form-control mb-2" id="userFilterInput" type="text" v-model="userFilter">

              <table class="table align-middle mb-0">
                <thead class="table-light">
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Date</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in filteredUsers" :key="'auser-' + user.id">
                  <td>{{user.id}}</td>
                  <td>{{user.fname}} {{user.lname}}</td>
                  <td><input class="form-control" type="date" format="dd/MM/yyyy" :id="'newdate-' + user.id"></td>
                  <td>
                    <button v-if="!user.selected" class="btn btn-sm btn-primary" @click="addUser(user)">Add User</button>
                    <button v-else class="btn btn-sm btn-danger" @click="removeUser(user)">Remove User</button>
                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id='"closeManageNewUsersJobModal" + id'>Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <new-site-form :id="id" @created="loadSites" ref="newsiteform"></new-site-form>
</template>

<script>
/* global $ */
import axios from "axios";
import Swal from "sweetalert2";
import SubmitButton from "../../../components/SubmitButton";
import Multiselect from '@vueform/multiselect'
import NewSiteForm from "../sites/NewSiteForm";
import {mapGetters} from 'vuex';

export default {
  name: "NewJobForm",
  emits: ['reload'],
  components: {
    SubmitButton,
    Multiselect,
    NewSiteForm
  },
  props: {
    site_id: {
      type: [String, Number],
      required: false
    },
    description: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    client_id: {
      type: [String, Number],
      required: false
    },
    id: {
      type: String,
      required: false
    }
  },
  data(){
    return {
      newJob: {
        site_id: null,
        description: null,
        type: null,
        brand_id: null,
        quote_number: null
      },
      sites: [],
      users: [],
      availableUsers: [],
      creating: false,
      siteBrands: [],
      existingJobs: [],
      steveAudio: null,
      showSteve: false,
      showAndy: false,
      userFilter: ""
    }
  },
  mounted(){
    if(this.description){
      this.newJob.description = this.description;
    }
    if(this.type){
      this.newJob.type = this.type;
    }
    if(this.user.email === 'se@ads-europe.co.uk'){
      this.showSteve = true;
    }
    // if(this.user.email === 'ad@ads-europe.co.uk' || this.user.email === 'lm@ads-europe.co.uk'){
    //   this.showAndy = true;
    // }
    this.loadSites();
    this.loadUsers();
  },
  methods: {
    show(){
        $('#' + this.id).modal('toggle');
        // if(this.showSteve){
        //   let audio = new Audio('/assets/images/steve.mp3');
        //   audio.play();
        // }else if(this.showAndy){
        //   let audio = new Audio('/assets/images/andy.mp3');
        //   audio.play();
        // }

      this.loadUsers();
    },
    focusUserFilter(){
      setTimeout(() => {
        document.getElementById('userFilterInput').focus();
      }, 500)
    },
    loadSites(id = null){
      let url = `${process.env.VUE_APP_API_URL}/v1/sites/list`;
      if(this.client_id){
        url += `?client_id=${this.client_id}`;
      }
      axios.get(url)
          .then(response => {
            this.sites = response.data.sites;
            this.sites.forEach((site) => {
              site.text = `(${site.client_name}) ${site.name}`;
              site.name = `(${site.client_name}) ${site.name}`;
            })
            if(!this.site_id){
              this.$nextTick(() => {
                if(id !== null){
                  this.newJob.site_id = id;
                  this.loadSiteBrands();
                  this.show();
                }else{
                  this.newJob.site_id = this.sites[0].id;
                  this.loadSiteBrands();
                }
              })
            }else{
              this.$nextTick(() => {
                if(id !== null){
                  this.newJob.site_id = id;
                  this.loadSiteBrands();
                  this.show();
                }else{
                  this.newJob.site_id = this.site_id;
                  this.loadSiteBrands();
                }
              });
            }
          })
          .catch(error => {
            this.$error("Failed to load sites", error);
          })
    },
    loadUsers(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/list`)
          .then(response => {
            this.availableUsers = response.data.users;
            this.availableUsers.forEach((user) => {
              user.selected = false;
            })
          })
          .catch(error => {
            this.$error("Failed to load users.", error);
          })
    },
    addUser(user){
      let date = document.getElementById('newdate-' + user.id);
      date = date.value;
      user.selected = true;
      this.users.push({
        id: user.id,
        user: user,
        date: date
      });
    },
    removeUser(user){
      this.users = this.users.filter((u) => {
        return u.id !== user.id;
      });
      let realUser = this.availableUsers.filter((u) => {
        return u.id === user.id;
      })[0];
      realUser.selected = false;
    },
    createJob(){
      this.creating = true;
      if(this.newJob.type === null || this.newJob.type === undefined){
        this.$error("Please select a Job Type");
        this.creating = false;
        return;
      }
      if(this.newJob.description === null || this.newJob.description === undefined){
        this.$error("Please enter a job description");
        this.creating = false;
        return;
      }
      if(this.newJob.reported_by === null || this.newJob.reported_by === undefined){
        this.$error("Please enter who reported the job");
        this.creating = false;
        return;
      }
      this.newJob.users = this.users;
      if(this.newJob.brand_id){
        this.newJob.brand_id = parseInt(this.newJob.brand_id);
        if(isNaN(this.newJob.brand_id)){
          this.newJob.brand_id = null;
        }
      }
      axios.put(`${process.env.VUE_APP_API_URL}/v1/jobs`, this.newJob)
          .then(response => {
            $('#closeCreateJobModal' + this.id).click();
            this.$success("Created new job");
            this.creating = false;
            this.newJob = {
              site_id: this.site_id,
              description: null,
              type: null,
              reported_by: null,
              brand_id: null
            }
            this.users = [];
            this.availableUsers = [];
            this.$emit('reload');

            if(response.data.reminders.length > 0){

              let message = '<strong>There are active reminders for the site you just made a job for. See below:</strong><br/><br/>';

              response.data.reminders.forEach((rm) => {
                message += rm.value + '<br/><br/>';
              })

              Swal.fire({
                icon: 'info',
                title: 'Heads Up!',
                html: message
              })

            }

          })
          .catch(error => {
            this.$error("Failed to create new job.", error);
            this.creating = false;
          })
    },
    openNewSite(){
      $('#closeCreateJobModal' + this.id).click();
      this.$refs.newsiteform.show();
    },
    openExistingJobs(){
      $('#closeCreateJobModal' + this.id).click();
      $('#existingJobsModal' + this.id).modal('toggle');
    },
    closeExistingJobs(){
      $('#existingJobsModal' + this.id).modal('toggle');
      this.show();
    },
    goTo(job){
      $('#existingJobsModal' + this.id).modal('toggle');
      this.$router.push({name: 'admin.jobs.view', params: {id: job.id}})
    },
    loadSiteBrands(){
      this.$nextTick(() => {
        this.checkForJobs();
        this.siteBrands = [];
        axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.newJob.site_id}/brands`)
            .then(response => {
              if(response.data.success){
                this.siteBrands = response.data.brands;
              }
            })
            .catch(error => {
              console.log(error);
            });
      })
    },
    checkForJobs(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs?site_id=${this.newJob.site_id}&status=-1`)
          .then(response => {
            this.existingJobs = response.data.jobs;
          })
          .catch(error => {
            this.$error("Unable to check for existing jobs", error);
          });
    }
  },
  computed: {
    ...mapGetters({
        user: 'auth/user'
    }),
    filteredUsers: function(){
      return this.availableUsers.filter((user) => {
        return `${user.fname} ${user.lname}`.toLowerCase().includes(this.userFilter.toLowerCase());
      })
    }
  }
}
</script>

<style scoped>

</style>

<style src="@vueform/multiselect/themes/default.css"></style>