<template>
  <div>

    <div class="card">
      <div class="card-body">

        <div class="position-relative wd-50 row">
          <div class="col-7">
            <div class="input-group">
              <input v-on:keyup="loadJobs(1)" type="text" class="form-control ps-5" v-model="filters.search" placeholder="Search Jobs..."> <span class="position-absolute top-50 product-show translate-middle-y" style="left: 25px;"><i class="bx bx-search"></i></span>
              <button class="btn btn-danger" type="button" @click="clearFilters">Clear</button>
              <submit-button type="button" text="Search" :clicked="loading" @click="loadJobs(1)" class="btn btn-primary px-5"></submit-button>
            </div>

          </div>
          <div class="col-4">
            <button type="button" v-if="!lockClientID && !lockSiteID" @click="downloadOpenJobsReport"  class="btn btn-info px-5">Open Jobs Report</button>
            <button type="button" @click="$refs.newjobform.show()" class="btn btn-primary px-5 ms-3">New Job</button>
          </div>
          <div class="col-1">
            <button type="button" class="btn btn-light ms-2" style="float: right;" title="Manage Columns" data-bs-toggle="modal" data-bs-target="#manageColumnsModal">
              <i class="bx bxs-grid me-0"></i>
            </button>
          </div>
        </div>

        <br/>

        <div class="position-relative wd-50 row">
          <div class="col-2">
            <select v-model="filters.status" style=" width: 100%;" class="form-select mb-3">
              <option selected="" value="-1">- Filter by Status -</option>
              <option value="0">TBA</option>
              <option value="5">Requires Prep</option>
              <option value="1">Completed</option>
              <option value="2">Assigned</option>
              <option value="3">Rejected</option>
              <option value="6">Quoted - Chase</option>
              <option value="7">Quoted - Close</option>
            </select>
          </div>
          <div class="col-2">
            <select v-model="filters.type" style=" width: 100%;" class="form-select mb-3">
              <option selected="" value="">- Filter by Type -</option>
              <option value="Installation">Installation</option>
              <option value="Service">Service</option>
              <option value="Maintenance">Maintenance</option>
              <option value="Repair">Repair</option>
              <option value="Investigation">Investigation</option>
              <option value="Remote">Remote</option>
              <option value="Delivery">Delivery</option>
              <option value="Phone / Email">Phone / Email</option>
              <option value="Snagging">Snagging</option>
              <option value="Planning">Planning</option>
              <option value="Survey">Survey</option>
            </select>
          </div>
          <div class="col-2">
            <select v-model="filters.sort_by" style=" width: 100%;" class="form-select mb-3">
              <option selected="" value="">- Sort By -</option>
              <option value="id">ID</option>
              <option value="created_at">Date Created</option>
              <option value="postcode">Postcode</option>
              <option value="site">Site</option>
              <option value="client">Client</option>
              <option value="completed_at">Completed At</option>
              <option value="completed_by">Completed By</option>
            </select>
          </div>
          <div v-show="parseInt(filters.status) === 1" class="col-6">
            <div class="input-group">
              <span class="input-group-text">Completed From</span>
              <input type="date" id="jobs-from-date" format="dd/MM/yyyy" aria-label="From Date" class="form-control">
              <span class="input-group-text">To</span>
              <input type="date" id="jobs-to-date" format="dd/MM/yyyy" aria-label="To Date" class="form-control">
            </div>
          </div>
        </div>

        <br/>

        <pagination @search="loadJobs" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
          <tr>
            <th></th>
            <th v-show="columns.id">#</th>
            <th v-show="columns.created_at">Date Created</th>
            <th v-show="columns.type">Type</th>
            <th v-show="columns.description" style="min-width: 50ch; white-space: normal;">Description</th>
            <th v-show="columns.town" style="min-width: 20ch; white-space: normal;">Town</th>
            <th v-show="columns.postcode">Postcode</th>
            <th v-show="columns.status">Status</th>
            <th v-show="columns.invoiced">Invoiced?</th>
            <th v-show="columns.users">Assigned Users</th>
            <th v-show="columns.site" style="min-width: 20ch; white-space: normal;">Site</th>
            <th v-show="columns.client">Client</th>
            <th v-show="columns.completed_at">Completed At</th>
            <th v-show="columns.completed_by">Completed By</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="jobs.length < 1">
            <td colspan="11" class="text-center">No jobs could be found.</td>
          </tr>
          <tr v-for="job in jobs" :key="job.id" style="cursor: pointer;">
            <td>
              <button class="btn btn-sm btn-primary" @click="downloadJobSheet(job)" v-if="job.status == 1"><i class="bx bxs-printer me-1"></i></button>
            </td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.id">{{job.id}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.created_at"><Time :datetime="job.created_at" format="dd/MM/yyyy"></Time></td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.type">{{job.type}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.description" style="min-width: 50ch; white-space: normal;">{{job.description}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.town" style="min-width: 20ch; white-space: normal;">{{job.site_town}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.postcode">{{job.site_postcode}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.status">
              <div v-if="job.status == 1" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-success"></i>COMPLETE</div>
              <div v-else-if="job.status == 2" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-warning"></i>ASSIGNED</div>
              <div v-else-if="job.status == 5" class="badge rounded-pill bg-warning p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-primary">REQUIRES PREP</i></div>
              <div v-else class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-danger"></i>TBA</div>

              <div v-if="job.status == 1 && (job.not_completed_notes || '').length > 10" class="badge rounded-pill bg-light p-2 text-uppercase px-3 ms-2"><i class="bx bxs-circle me-1 text-warning"></i>FURTHER WORK</div>
            </td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.invoiced">
              <div v-if="job.invoiced == 1" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-success"></i>INVOICED</div>
              <div v-else-if="job.invoiced == 2" class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-success"></i>MAINTENANCE</div>
              <div v-else class="badge rounded-pill bg-light p-2 text-uppercase px-3"><i class="bx bxs-circle me-1 text-warning"></i>NOT INVOICED</div>
            </td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.users">
              <span v-if="job.users.length < 1">None</span>
              <ul v-else v-for="user in job.users" :key="'jobuser-' + user.id" style="list-style-type:none;">
                <li>{{user.fname}} {{user.lname}} ({{ user.date }})</li>
              </ul>
            </td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.site" style="min-width: 20ch; white-space: normal;">{{job.site_name}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.client">{{job.client_name}}</td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.completed_at"><Time v-if="job.completed_at" :datetime="job.completed_at" format="dd/MM/yyyy"></Time></td>
            <td @click="$router.push({name: 'admin.jobs.view', params: {id: job.id}})" v-show="columns.completed_by"><span v-if="job.completed_user">{{job.completed_user.fname}} {{job.completed_user.lname}}</span></td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th></th>
            <th v-show="columns.id">#</th>
            <th v-show="columns.created_at">Date Created</th>
            <th v-show="columns.type">Type</th>
            <th v-show="columns.description" style="min-width: 50ch; white-space: normal;">Description</th>
            <th v-show="columns.town" style="min-width: 20ch; white-space: normal;">Town</th>
            <th v-show="columns.postcode">Postcode</th>
            <th v-show="columns.status">Status</th>
            <th v-show="columns.invoiced">Invoiced?</th>
            <th v-show="columns.users">Assigned Users</th>
            <th v-show="columns.site" style="min-width: 20ch; white-space: normal;">Site</th>
            <th v-show="columns.client">Client</th>
            <th v-show="columns.completed_at">Completed At</th>
            <th v-show="columns.completed_by">Completed By</th>
          </tr>
          </tfoot>
        </table>

        <pagination @search="loadJobs" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->
    <new-job-form id="jobsNewJobForm" v-if="!$hasRole('Customer')" @reload="loadJobs(pagination.page)" :site_id="lockSiteID" :client_id="lockClientID" ref="newjobform"></new-job-form>

    <div class="modal fade" id="manageColumnsModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Columns</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please note these settings will be saved locally in your browser. If you clear your browser settings these settings may reset.</p>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="idColumn" @change="updateColumnSettings" v-model="columns.id">
              <label class="form-check-label" for="idColumn">ID</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="created_atColumn" @change="updateColumnSettings" v-model="columns.created_at">
              <label class="form-check-label" for="created_atColumn">Created At</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="typeColumn" @change="updateColumnSettings" v-model="columns.type">
              <label class="form-check-label" for="typeColumn">Type</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="descriptionColumn" @change="updateColumnSettings" v-model="columns.description">
              <label class="form-check-label" for="descriptionColumn">Description</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="townColumn" @change="updateColumnSettings" v-model="columns.town">
              <label class="form-check-label" for="townColumn">Town</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="postcodeColumn" @change="updateColumnSettings" v-model="columns.postcode">
              <label class="form-check-label" for="postcodeColumn">Postcode</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="statusColumn" @change="updateColumnSettings" v-model="columns.status">
              <label class="form-check-label" for="statusColumn">Status</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="invoicedColumn" @change="updateColumnSettings" v-model="columns.invoiced">
              <label class="form-check-label" for="invoicedColumn">Invoiced</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="usersColumn" @change="updateColumnSettings" v-model="columns.users">
              <label class="form-check-label" for="usersColumn">Users</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="siteColumn" @change="updateColumnSettings" v-model="columns.site">
              <label class="form-check-label" for="siteColumn">Site</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="clientColumn" @change="updateColumnSettings" v-model="columns.client">
              <label class="form-check-label" for="clientColumn">Client</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="completed_atColumn" @change="updateColumnSettings" v-model="columns.completed_at">
              <label class="form-check-label" for="completed_atColumn">Completed At</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="completed_byColumn" @change="updateColumnSettings" v-model="columns.completed_by">
              <label class="form-check-label" for="completed_byColumn">Completed By</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeManageColumnsModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Pagination from '../../../components/Pagination.vue';
import SubmitButton from '../../../components/SubmitButton.vue';
// import Select2 from '../../../components/Select2.vue';
// import SecureImage from '../../../components/SecureImage.vue';
import Time from '../../../components/Time.vue';
import { saveAs } from 'file-saver';
import NewJobForm from "./NewJobForm";
import { DateTime } from 'luxon';
export default {
  components: {NewJobForm, Pagination, SubmitButton, Time },
  name: 'admin.jobs',
  props: {
    lockClientID: {
      type: Number,
      required: false
    },
    lockSiteID: {
      type: Number,
      required: false
    }
  },
  data(){
    return {
      jobs: [],
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      },
      filters: {
        search: "",
        showCompleted: false,
        type: "",
        status: "-1",
        counter: 0,
        fromDate: null,
        toDate: null,
        sort_by: "",
        page: 1
      },
      job: {
        site_id: null,
        description: null
      },
      creating: false,
      availableUsers: [],
      loading: true,
      columns: {
        id: true,
        created_at: true,
        type: true,
        description: true,
        town: true,
        postcode: true,
        status: true,
        invoiced: true,
        users: true,
        site: true,
        client: true,
        completed_at: true,
        completed_by: true
      }
    }
  },
  mounted(){
    if(this.$hasRole('Customer')){
      this.$router.push({name: 'customer.jobs'});
    }else{
      this.loadFilters();
      this.loadColumnSettings();
    }
  },
  methods: {
    clearFilters(){
        this.filters.search = "";
        this.filters.showCompleted = false;
        this.filters.type = "";
        this.filters.status = "-1";
        this.filters.counter = 0;
        this.filters.fromDate = null;
        this.filters.toDate = null;
        this.filters.sort_by = "";
        this.page = 1;
    },
    loadFilters(){
      if(this.lockSiteID !== undefined || this.lockClientID !== undefined){
        this.loadJobs(this.filters.page || 1);
        return;
      }
      let savedFilters = localStorage.getItem('link::jobs::filters');
      if(savedFilters !== undefined && savedFilters !== null && savedFilters !== 'null' && savedFilters !== ''){
        this.filters = JSON.parse(savedFilters);
        let fromDate = document.getElementById('jobs-from-date');
        fromDate.value = this.filters.fromDate;
        let toDate = document.getElementById('jobs-to-date');
        toDate.value = this.filters.toDate;
      }
      this.loadJobs(this.filters.page || 1);
    },
    saveFilters(){
      localStorage.setItem('link::jobs::filters', JSON.stringify(this.filters));
    },
    loadJobs(page = 1){
      this.loading = true;
      this.filters.counter += 1;
      let fromDate = document.getElementById('jobs-from-date');
      fromDate = fromDate.value;
      let toDate = document.getElementById('jobs-to-date');
      toDate = toDate.value;
      if(parseInt(this.filters.status) !== 1){
        fromDate = "";
        toDate = "";
      }
      this.filters.fromDate = fromDate;
      this.filters.toDate = toDate;
      this.filters.page = page;
      this.saveFilters();
      console.log(this.filters.search);
      let search = encodeURIComponent(this.filters.search);
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs?page=${page}&search=${search}&showcompleted=${this.filters.showCompleted}&type=${this.filters.type.trim()}
      &status=${this.filters.status}&counter=${this.filters.counter}&toDate=${toDate}&fromDate=${fromDate}&sort_by=${this.filters.sort_by}&client_id=${this.lockClientID || ""}&site_id=${this.lockSiteID || ""}`)
      .then(response => {
        if(response.data.counter == this.filters.counter){
        response.data.jobs.forEach((job) => {
          if(job.users === null){
            job.users = [];
          }
          job.users.forEach((user) => {
            user.date = DateTime.fromSeconds(user.date).toFormat('dd/MM/yyyy');
          });
        });
          this.jobs = response.data.jobs;
          this.jobs.forEach((job) => {
            switch(job.status){
              case null:
              case 0:
                job.realStatus = 'TBA';
                break;
              case 1:
                job.realStatus = 'Completed';
                break;
              case 2:
                job.realStatus = 'Assigned';
                break;
            }
          })
          this.pagination = response.data.pagination;
          this.$emit('jobsLoaded', this.pagination.total);
        }
      })
      .catch(error => {
        this.$error('Failed to load jobs.', error);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    downloadOpenJobsReport(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/reports`, {
        responseType: "blob"
      })
      .then(response => {
        saveAs(response.data, 'open-jobs-report.pdf');
      })
      .catch(error => {
        this.$error("Failed to generate report", error);
      })
    },
    updateColumnSettings(){
      localStorage.setItem('link::jobs::columns', JSON.stringify(this.columns));
    },
    loadColumnSettings(){
      let savedColumns = localStorage.getItem('link::jobs::columns');
      if(savedColumns !== undefined && savedColumns !== null && savedColumns !== 'null' && savedColumns !== ''){
        this.columns = JSON.parse(savedColumns);
      }
    },
    downloadJobSheet(job){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/jobs/${job.id}/jobsheet`, {
        responseType: "blob"
      })
      .then(response => {
        saveAs(response.data, `Job Report #${job.id}.pdf`);
      })
      .catch(error => {
        this.$error("Failed to generate report", error);
      })
    },
  }
}
</script>

<style scoped>
  ul {
    margin-block-start: 0;
    padding-inline-start: 0;
  }

  td, th {
    width: 1%;
    white-space: nowrap;
  }
  table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
</style>

<style src="@vueform/multiselect/themes/default.css"></style>