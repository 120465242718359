<template>
  <div class="row">

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-9">
              <div class="ps-3 font-24">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0 p-0">
                    <li class="breadcrumb-item"><a href="javascript:;" @click="currentPath = []"><i class="bx bx-home-alt"></i></a></li>
                    <li v-for="node in currentPath.slice(0, currentPath.length - 1)" :key="node" class="breadcrumb-item"><a href="javascript:;" @click="setPath(node)">{{node}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{currentPath[currentPath.length - 1]}}</li>
                  </ol>
                </nav>
              </div>
            </div>
            <div class="col-3">
              <button href="javascript:;" class="btn btn-light float-end ms-2" data-bs-toggle="modal" data-bs-target="#createFileModal">+ Add File</button>
              <button href="javascript:;" class="btn btn-light float-end" data-bs-toggle="modal" data-bs-target="#createFolderModal">+ Add Folder</button>
            </div>
          </div>

          <div class="table-responsive mt-3">
            <table class="table table-striped table-hover table-sm mb-0">
              <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Type</th>
                <th>Uploaded By</th>
                <th>Last Modified</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                <tr v-if="hasParentNode" @click="upOneNode" style="cursor: pointer; user-select: none;">
                  <td></td>
                  <td>
                    <div class="d-flex align-items-center">
                      <div>
                        <i class='bx bx-folder me-2 font-24'></i>
                      </div>
                      <div class="font-weight-bold">..</div>
                    </div>
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr v-for="folder in currentFolders" :key="folder.name">
                  <td></td>
                  <td @click="downOneNode(folder.name)" style="cursor: pointer; user-select: none;">
                    <div class="d-flex align-items-center">
                      <div>
                        <i class='bx bx-folder me-2 font-24'></i>
                      </div>
                      <div class="font-weight-bold">{{folder.name}}</div>
                    </div>
                  </td>
                  <td @click="downOneNode(folder.name)" style="cursor: pointer; user-select: none;">-</td>
                  <td @click="downOneNode(folder.name)" style="cursor: pointer; user-select: none;">-</td>
                  <td @click="downOneNode(folder.name)" style="cursor: pointer; user-select: none;">-</td>
                  <td>
                    <i class="bx bx-dots-horizontal-rounded font-24" data-bs-toggle="dropdown" aria-expanded="false"></i>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item text-danger" href="javascript:void(0);" @click="deleteFolder(folder)">Delete</a></li>
                    </ul>
                  </td>
                </tr>
                <tr v-for="file in currentFiles" :key="file.id">
                  <td>
                    <secure-image style="width: 32px; height: 32px;" v-if="file.properties && file.properties.mime.includes('image/')" :endpoint="`/v1/sites/${site.id}/files/file?path=${currentPath.join('/').length < 1 ? file.name : ('site-' + site.id + '/' + currentPath.join('/') + '/' + file.name)}`" :alt="file.name" />
                    <secure-image style="width: 32px; height: 32px;" v-if="file.properties && file.properties.job_id !== undefined"  :id="'jobimagepic-'+file.properties.image_id" :endpoint="`/v1/jobs/${file.properties.job_id}/images/${file.properties.image_id}`"></secure-image>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <div>
                        <i v-if="file.filetype === 'pdf'" class="bx bxs-file-pdf me-2 font-24"></i>
                        <i v-else-if="file.filetype === 'jpg'" class='bx bxs-file-jpg me-2 font-24'></i>
                        <i v-else class="bx bxs-file me-2 font-24"></i>
                      </div>
                      <div class="font-weight-bold">{{file.name}}</div>
                    </div>
                  </td>

                  <td>
                    <span v-if="file.properties">{{file.properties.mime || "-"}}</span>
                    <span v-else>-</span>
                  </td>
                  <td>
                    <span v-if="file.properties">{{file.properties.createdBy || file.properties.uploaded_by || "-"}}</span>
                    <span v-else>-</span>
                  </td>
                  <td><Time v-if="file.properties" :datetime="file.properties.lastModified" format="MMM dd, yyyy"></Time></td>
                  <td>
                    <i class="bx bx-dots-horizontal-rounded font-24" data-bs-toggle="dropdown" aria-expanded="false"></i>
                    <ul class="dropdown-menu">
<!--                      <li><a class="dropdown-item" v-if="file.allowedPreview" href="javascript:void(0);">Preview</a></li>-->
                      <li><a class="dropdown-item" href="javascript:void(0);" @click="downloadFile(file)">Download</a></li>
                      <li><hr class="dropdown-divider"></li>
                      <li><a class="dropdown-item text-danger" href="javascript:void(0);" @click="deleteFile(file)">Delete</a></li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  <div class="modal fade" id="createFolderModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">New Folder</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <label for="newFolderName" class="form-label">Folder Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="newFolderName" v-model="newFolder.name">
              </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateFolderModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creatingFolder" @click="createFolder" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="createFileModal" tabindex="-1" style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New File</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 mb-2">
              <label for="newFile" class="form-label">File <span class="text-danger">*</span></label>
              <input type="file" class="form-control" id="newFile" multiple @change="selectFile">
            </div>

            <div v-if="upload.total > 0" class="col-md-12 mb-2">
                <p>Uploaded {{upload.completed}} of {{upload.total}} files</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateFileModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creatingFile" @click="uploadFiles" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Time from '../../../components/Time.vue';
import SubmitButton from '../../../components/SubmitButton.vue';
import SecureImage from '../../../components/SecureImage.vue';
// import {DateTime} from 'luxon';
import saveAs from 'file-saver';
export default {
  name: "FileManager",
  components: {SubmitButton, Time, SecureImage},
  props: {
      site: Object
  },
  data() {
    return {
      files: {
        folders: [
          {
            name: "images",
            folders: [
              {
                name: "Install Pics",
                folders: [],
                files: []
              }
            ],
            files: []
          },
          {
            name: "Documents",
            folders: [],
            files: []
          }
        ],
        files: [
          {
            id: 1,
            name: "Snagging Report",
            filetype: "pdf",
            uploaded_by: "Steve Elms",
            last_modified: "Mar 3, 2022"
          },
          {
            id: 2,
            name: "Site Drawings",
            filetype: "pdf",
            uploaded_by: "Steve Sharples",
            last_modified: "Feb 27, 2022"
          },
          {
            id: 3,
            name: "Router location",
            filetype: "jpg",
            uploaded_by: "Tom Poppleton",
            last_modified: "Feb 26, 2022"
          }
        ]
      },
      rootPath: [],
      currentPath: [],
      newFolder: {
        name: ""
      },
      creatingFolder: false,
      newFile: {
        file: null
      },
      creatingFile: false,
      currentFile: null,
      upload: {
        total: 0,
        completed: 0,
        errors: []
      }
    }
  },
  mounted(){
    this.loadFiles();
  },
  methods: {
    loadFiles(){
        axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/files`)
        .then(response => {
          this.files = response.data.files;
        })
      .catch(error => {
        this.$error("Failed to load files", error);
      })
    },
    setPath(node){
        this.currentPath = this.currentPath.slice(0, this.currentPath.indexOf(node) + 1);
    },
    upOneNode(){
      if(!this.hasParentNode) return;
      this.currentPath.pop();
    },
    downOneNode(folder){
      this.currentPath.push(folder);
    },
    createFolder(){
      this.creatingFolder = true;
      let path = this.currentPath.join('/');
      if(this.currentPath.length < 1){
        path = this.newFolder.name;
      }else{
        path = path + '/' + this.newFolder.name;
      }
      axios.put(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/files/folder`, {
        path: path,
        name: this.newFolder.name
      })
      .then(response => {
        if(response.data.success){
          this.$success("Created a new folder");
          this.loadFiles();
          $('#closeCreateFolderModal').click();
        }else{
          this.$error(response.data.message, null);
        }
      })
      .catch(error => {
        this.$error("Failed to create new folder", error);
      })
      .finally(() => {
        this.creatingFolder = false;
      })
    },
    selectFile(event){
      this.newFile.file = event.target.files;
    },
    async uploadFiles(){
      this.upload.total = this.newFile.file.length;
      this.creatingFile = true;
      console.log(`Uploading ${this.newFile.file.length} files`);
      for await (let file of this.newFile.file){
        console.log(`Uploading ${file.name}...`);
        try{
          await this.createFile(file);
        }catch(error){
          console.log(error);
          this.upload.errors.push(file);
        }
        this.upload.completed++;
      }
      console.log(`Done!`);
      this.$success("Created a new files");
      this.loadFiles();
      $('#closeCreateFileModal').click();
      this.creatingFile = false;
    },
    async createFile(file){
      let path = this.currentPath.join('/');
      if(this.currentPath.length < 1){
        path = file.name;
      }else{
        path = path + '/' + file.name;
      }
      let formData = new FormData();
      formData.append("file", file);
      formData.append('path', path);
      formData.append('name', file.name);
      return axios.put(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/files`, formData);
          // .then(response => {
          //   if(response.data.success){
          //     this.$success("Created a new file");
          //     // this.currentFiles.push({
          //     //   name: this.newFile.file.name,
          //     //   properties: {
          //     //     createdOn: DateTime.local().toSeconds(),
          //     //     lastModified: DateTime.local().toSeconds(),
          //     //     createdBy: this.$store.state.auth.user.fname + ' ' + this.$store.state.auth.user.lname
          //     //   }
          //     // });
          //     this.loadFiles();
          //     $('#closeCreateFileModal').click();
          //   }else{
          //     this.$error(response.data.message, null);
          //   }
          // })
    },
    deleteFile(file){
      let path = this.currentPath.join('/');
      if(this.currentPath.length < 1){
        path = file.name;
      }else{
        path = path + '/' + file.name;
      }
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/files`, {
        data: {
          path: path
        }
      })
      .then(response => {
        if(response.data.success){
          file.deleted = true;
          this.$success("Deleted file");
        }else{
          this.$error(response.data.message, null);
        }
      })
      .catch(error => {
        this.$error("Failed to delete file", error);
      })
    },
    deleteFolder(folder){
      let path = this.currentPath.join('/');
      if(this.currentPath.length < 1){
        path = folder.name;
      }else{
        path = path + '/' + folder.name;
      }
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/files/folder`, {
        data: {
          path: path
        }
      })
          .then(response => {
            if(response.data.success){
              folder.deleted = true;
              this.$success("Deleted folder");
            }else{
              this.$error(response.data.message, null);
            }
          })
          .catch(error => {
            this.$error("Failed to delete folder", error);
          })
    },
    downloadFile(file){
      let path = this.currentPath.join('/');
      if(this.currentPath.length < 1){
        path = file.name;
      }else{
        path = path + '/' + file.name;
      }

      path = 'site-' + this.site.id + '/' + path;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/files/file?path=${path}`, {
        responseType: 'arraybuffer'
      })
      .then(response => {
        saveAs(new Blob([response.data]), file.name);
      })
      .catch(error => {
        this.$error("Failed to download file!", error);
      })
    }
  },
  computed: {
    path: function(){
      let path = "/files";
      this.currentPath.forEach((e) => {
        path += `/${e}`;
      });
      return path;
    },
    currentFiles: function(){
      let files = this.files;
      this.currentPath.forEach((e) => {
        files = files.folders.filter((f) => {
          return f.name === e;
        })[0];
      });
      files.files.forEach((f) => {

        // Check if the file has a valid mime-type
        f.allowedPreview = false;
        if(f.properties){
          if(f.properties.mime){
            if(f.properties.mime.includes("video/")) f.allowedPreview = true;
            if(f.properties.mime.includes("image/")) f.allowedPreview = true
            if(f.properties.mime.includes("application/pdf")) f.allowedPreview = true;
          }
        }

      });
      return files.files.filter((f) => {
        return f.deleted !== true;
      });
    },
    currentFolders: function(){
      let files = this.files;
      this.currentPath.forEach((e) => {
        files = files.folders.filter((f) => {
          return f.name === e;
        })[0];
      });
      return files.folders.filter((f) => {
        return f.deleted !== true;
      });
    },
    hasParentNode: function(){
      return this.currentPath.length > 0;
    }
  }
}
</script>

<style scoped>

</style>