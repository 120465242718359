<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ul class="nav nav-pills" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" data-bs-toggle="pill" href="#site-technical" role="tab" aria-selected="true">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Technical Information</div>
                  </div>
                </a>
              </li>
              <li v-if="site.id && $hasOneOfRoles('Support', 'Admin')" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#site-attributes" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Attributes</div>
                  </div>
                </a>
              </li>
              <li v-if="site.id" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#site-jobs" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Jobs ({{jobsCounter}})</div>
                  </div>
                </a>
              </li>
              <li v-if="site.id && $hasOneOfRoles('Admin', 'Support')" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#site-stats" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Stats</div>
                  </div>
                </a>
              </li>
              <li v-if="site.id && $hasOneOfRoles('Admin', 'Support')" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#site-files" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Files</div>
                  </div>
                </a>
              </li>
              <li v-if="site.id && $hasOneOfRoles('Admin', 'Support')" class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="pill" href="#site-maintenance" role="tab" aria-selected="false">
                  <div class="d-flex align-items-center">
                    <div class="tab-title">Maintenance</div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-content" id="pills-tabContent">

      <div class="tab-pane fade active show" id="site-technical" role="tabpanel">
        <!-- Site Information -->
        <div class="row">

          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                  <div>
                    <div v-for="brand in site.brands" :key="brand.name + '-logo'" style="display: inline-block;">
                      <secure-image v-if="brand.logo" :id="brand.name + '-logo'" :endpoint="`/v1/brands/${brand.id}/logo`" style="width: 100px;"></secure-image>
                    </div>
                  </div>
                  <div class="mt-3">
                    <h4 v-if="!edit">{{site.name}}</h4>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="site.name">
                    <h6 v-if="site.client">Operated by {{site.client.name}}</h6>
                  </div>
                </div>
                <hr class="my-4">
                <ul class="list-group list-group-flush mb-2">
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Street</h6>
                    <span v-if="!edit" class="text-white">{{site.street || "N/A"}}</span>
                    <div v-else class="input-group">
                      <input class="form-control" maxlength="100" style="width: 75%;" type="text" v-model="site.street">
                      <span class="input-group-text" id="siteStreetLength">{{site.street?.length || 0}}/100</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Town</h6>
                    <span v-if="!edit" class="text-white">{{site.town || "N/A"}}</span>
                    <div v-else class="input-group">
                      <input class="form-control" maxlength="100" style="width: 75%;" type="text" v-model="site.town">
                      <span class="input-group-text" id="siteTownLength">{{site.town?.length || 0}}/100</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">County</h6>
                    <span v-if="!edit" class="text-white">{{site.county || "N/A"}}</span>
                    <div v-else class="input-group">
                      <input class="form-control" maxlength="100" style="width: 75%;" type="text" v-model="site.county">
                      <span class="input-group-text" id="siteCountyLength">{{site.county?.length || 0}}/100</span>
                    </div>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Postcode</h6>
                    <span v-if="!edit" class="text-white">{{site.postcode || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="site.postcode">
                  </li>
                   <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Latitude</h6>
                    <span v-if="!edit" class="text-white">{{site.latitude || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="site.latitude">
                  </li>
                   <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Longitude</h6>
                    <span v-if="!edit" class="text-white">{{site.longitude || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="site.longitude">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Telephone</h6>
                    <span v-if="!edit" class="text-white"><i :title="site.telephone_verified < 1 ? 'Not Verified' : 'Verified'" :class="{'bx bx-x text-danger': site.telephone_verified === 0, 'bx bx-check text-success': site.telephone_verified === 1}"></i> {{site.telephone || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="tel" v-model="site.telephone">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Email</h6>
                    <span v-if="!edit" class="text-white"> {{site.email || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="email" v-model="site.email">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Status</h6>
                    <span v-if="!edit" class="text-white">{{site.status || "N/A"}}</span>
                    <select class="form-control form-control-dark form-select" v-else v-model="site.status">
                      <option v-for="status in statuses" :key="'site-status-' + status.name">{{status.name}}</option>
                    </select>
                  </li>
                </ul>

                <submit-button :background="'btn btn-sm btn-primary ms-2'" style="float: right;" :clicked='updating' @click="saveChanges" :text="'Save Changes'"></submit-button>
                <button class="btn btn-sm ms-2" :class="{'btn-light': !edit, 'btn-danger': edit}" @click="edit = !edit" style="float: right;">{{ edit ? 'Stop Editing' : 'Edit' }}</button>
                <button v-if="$hasRole('Support')" class="btn btn-sm ms-2 btn-warning" style="float: right;" data-bs-toggle="modal" data-bs-target="#migrateSiteClient">Migrate Site</button>
                <button class="btn btn-sm btn-info ms-2" style="float: right;" @click="copyAddress">Copy Address</button>
                <button class="btn btn-sm btn-danger" style="float: right;" @click="openDeleteModal">Delete</button>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Technical Information</h5>
                <ul class="list-group list-group-flush mb-2">
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">IP Range and Subnet</h6>
                    <span v-if="!edit" class="text-white">{{site.range_and_subnet || "N/A"}}<template v-for="sn in site.subnets">{{', ' + sn.cidr_block}}</template></span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="site.range_and_subnet">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Gateway</h6>
                    <span v-if="!edit" class="text-white">{{site.gateway || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="site.gateway">
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Client Assigned ID</h6>
                    <span v-if="!edit" class="text-white">{{site.client_assigned_id || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 75%;" type="text" v-model="site.client_assigned_id">
                  </li>
                </ul>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Install Information</h5>
                <ul class="list-group list-group-flush mb-2">
                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">Install Date</h6>
                    <span class="text-white" v-if="!edit"><Time v-if="site.formattedInstallDate" :datetime="site.formattedInstallDate" format="dd/MM/yyyy"></Time> <span v-else>N/A</span> </span>
                    <input v-else class="form-control" style="width: 75%;" type="date" v-model="site.install_date">
                  </li>
<!--                  <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">-->
<!--                    <h6 class="mb-0">Install Date</h6>-->
<!--                    <span class="text-white" v-if="site.install_date"><Time :datetime="site.install_date" format="dd/MM/yyyy"></Time> </span>-->
<!--                  </li>-->
                </ul>
              </div>
            </div>
            <div v-show="site.latitude" class="card">
              <div class="card-body" style="min-height: 254px;">
                <h5 class="card-title">Site Location</h5>
                <site-map v-if="site.latitude" :height="'254px'" :width="'100%'" :label="site.name" :latitude="site.latitude" :longitude="site.longitude"></site-map>
              </div>
            </div>
          </div>

          <!-- BRAND IDENTIFIERS -->
          <div class="col-lg-4">
            <div class="card" style="margin-bottom: 20px;">
              <div class="card-body">
                <h5 class="card-title">Brand Identifiers</h5>
                <ul class="list-group list-group-flush mb-2">
                  <li v-for="brand in site.brands" :key="'brand-'+brand.id" class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <h6 class="mb-0">{{brand.name}}</h6>
                    <span v-if="!editBrands" class="text-white">{{brand.brand_site_id || "N/A"}}</span>
                    <input v-else class="form-control" style="width: 50%;" type="text" v-model="brand.brand_site_id">
                    <button v-if="editBrands" class="btn btn-sm btn-danger ms-2" @click="deleteBrand(brand.id)">Delete</button>
                  </li>
                </ul>

                <submit-button :background="'btn btn-sm btn-primary ms-2'" style="float: right;" :clicked='updatingBrands' @click="updateBrands" :text="'Save Changes'"></submit-button>
                <button class="btn btn-sm ms-2" :class="{'btn-light': !editBrands, 'btn-danger': editBrands}" @click="editBrands = !editBrands" style="float: right;">{{ editBrands ? 'Stop Editing' : 'Edit' }}</button>
                <submit-button class="btn btn-sm btn-success float-end" data-bs-toggle="modal" data-bs-target="#addBrandToSite" text="Add Brand" :clicked="brand.active"/>
              </div>
            </div>

            <site-notes v-if="site.id" :site_id="site.id" @notecount="(count) => notesCounter = count"></site-notes>
          </div>

        </div>
        <!-- Site Devices -->
        <div v-if="$hasOneOfRoles('Admin', 'Support')" class="row mt-2 mb-5">
          <div class="col-12">
            <div class="card">
              <div class="card-body">

                <site-nodes v-if="site.name" :site="site"></site-nodes>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="site.id && $hasOneOfRoles('Support', 'Admin')" class="tab-pane fade" id="site-attributes" role="tabpanel">

        <site-attributes :site="site"></site-attributes>

      </div>

      <div v-if="site.id" class="tab-pane fade" id="site-jobs" role="tabpanel">

        <h3>{{site.name}}</h3>

        <AdminJobs @jobsLoaded="updateJobsCounter" v-if="site.name && !$hasOneOfRoles('Customer')" :lockSiteID="site.id"></AdminJobs>
        <CustomerJobs @jobsLoaded="updateJobsCounter" v-if="site.name && $hasOneOfRoles('Customer')" :lockSiteID="site.id"></CustomerJobs>

      </div>

      <div v-if="site.id && $hasOneOfRoles('Admin', 'Support')" class="tab-pane fade" id="site-stats" role="tabpanel">

        <site-stats :site_id="site.id"></site-stats>

      </div>

      <div v-if="site.id && $hasOneOfRoles('Admin', 'Support')" class="tab-pane fade" id="site-files" role="tabpanel">

        <site-file-manager :site="site"></site-file-manager>

      </div>

      <div v-if="site.id && $hasOneOfRoles('Admin', 'Support')" class="tab-pane fade" id="site-maintenance" role="tabpanel">

        <site-maintenance :site="site"></site-maintenance>

      </div>

    </div>

    <!-- Modals -->

    <div class="modal fade" id="migrateSiteClient" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Move site to another client</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please note the client must already exist to move the site</p>
            <select v-model="site.client_id" class="form-select">
              <option v-for="client in clients" :key="'client-' + client.id" :value="client.id">{{client.name}}</option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeMigrateSiteClient'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" @click="migrateSite" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="addBrandToSite" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add brand to site</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="col-md-12 mb-2">
              <p>Please select a brand from the dropdown to add to the site</p>
              <select v-model="brand.id" class="form-select">
                <option v-for="b in brands" :key="'brand-' + b.id" :value="b.id">{{b.name}}</option>
              </select>
            </div>
            <div class="col-md-12 mb-2">
              <label for="customerBrandID" class="form-label">Customer Brand ID</label>
              <input type="text" class="form-control" id="customerBrandID" v-model="brand.identifier">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeAddBrandToSite'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" @click="addBrand" text="Save changes" :clicked="brand.active"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="deleteSiteModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete site</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="col-md-12 mb-2">
              <p><strong class="text-danger">IMPORTANT: </strong>Deleting a site is a permanent action and is irreversible. Please make sure you know exactly what you are doing before continuing!</p>
            </div>
            <div class="col-md-12 mb-2">
              <table>
                <tr v-show="deleteSite.checking">
                  <td>
                    <div class="spinner-border text-info" role="status"> <span class="visually-hidden">Loading...</span></div>
                  </td>
                  <td class="mx-2">Checking if this is a good idea...</td>
                </tr>
                <div v-if="deleteSite.canDelete && !deleteSite.checking">
                  <tr>
                    <td>
                      <i v-show="deleteSite.actions.documents.status == 0" class="bx bx-hourglass"></i>
                      <div v-show="deleteSite.actions.documents.status > 0" class="spinner-border text-info" role="status"> <span class="visually-hidden">Loading...</span></div>
                      <i v-show="deleteSite.actions.documents.status == -1" class="bx bx-check text-success"></i>
                      <i v-show="deleteSite.actions.documents.status == -2" class="bx bx-error text-danger"></i>
                    </td>
                    <td class="mx-2">{{deleteSite.actions.documents.count}} documents to be deleted</td>
                  </tr>
                  <tr>
                    <td>
                      <i v-show="deleteSite.actions.subnets.status == 0" class="bx bx-hourglass"></i>
                      <div v-show="deleteSite.actions.subnets.status > 0" class="spinner-border text-info" role="status"> <span class="visually-hidden">Loading...</span></div>
                      <i v-show="deleteSite.actions.subnets.status == -1" class="bx bx-check text-success"></i>
                      <i v-show="deleteSite.actions.subnets.status == -2" class="bx bx-error text-danger"></i>
                    </td>
                    <td class="mx-2">{{deleteSite.actions.subnets.count}} subnets to be deleted</td>
                  </tr>
                  <tr>
                    <td>
                      <i v-show="deleteSite.actions.nodes.status == 0" class="bx bx-hourglass"></i>
                      <div v-show="deleteSite.actions.nodes.status > 0" class="spinner-border text-info" role="status"> <span class="visually-hidden">Loading...</span></div>
                      <i v-show="deleteSite.actions.nodes.status == -1" class="bx bx-check text-success"></i>
                      <i v-show="deleteSite.actions.nodes.status == -2" class="bx bx-error text-danger"></i>
                    </td>
                    <td class="mx-2">{{deleteSite.actions.nodes.count}} devices to be deleted</td>
                  </tr>
                  <tr>
                    <td>
                      <i v-show="deleteSite.actions.nodeLogins.status == 0" class="bx bx-hourglass"></i>
                      <div v-show="deleteSite.actions.nodeLogins.status > 0" class="spinner-border text-info" role="status"> <span class="visually-hidden">Loading...</span></div>
                      <i v-show="deleteSite.actions.nodeLogins.status == -1" class="bx bx-check text-success"></i>
                      <i v-show="deleteSite.actions.nodeLogins.status == -2" class="bx bx-error text-danger"></i>
                    </td>
                    <td class="mx-2">{{deleteSite.actions.nodeLogins.count}} device credentials to be deleted</td>
                  </tr>
                  <tr>
                    <td>
                      <i v-show="deleteSite.actions.nodePings.status == 0" class="bx bx-hourglass"></i>
                      <div v-show="deleteSite.actions.nodePings.status > 0" class="spinner-border text-info" role="status"> <span class="visually-hidden">Loading...</span></div>
                      <i v-show="deleteSite.actions.nodePings.status == -1" class="bx bx-check text-success"></i>
                      <i v-show="deleteSite.actions.nodePings.status == -2" class="bx bx-error text-danger"></i>
                    </td>
                    <td class="mx-2">{{deleteSite.actions.nodePings.count.toLocaleString()}} ping logs to be deleted</td>
                  </tr>
                </div>
              </table>
              <div v-show="!deleteSite.checking && !deleteSite.canDelete">
                <p>This site cannot be deleted for the following reasons:</p>
                <li v-for="reason in deleteSite.reasons" :key="reason">{{reason}}</li>
                <br/>
                <p>If you still wish to delete the site, please migrate all jobs to prove it!</p>
              </div>
              <div v-if="!deleteSite.checking && deleteSite.canDelete">

                <br/>
                <p>This site can be deleted, however please review the above table as several other entities will be deleted at the same time.</p>
                <p><strong class="text-warning">WARNING: </strong>Depending on the number of logs attached to this site, this process could take several minutes to complete. You must not close your browser during this time otherwise the process will only partially complete!</p>

              </div>
              <div v-if="deleteSite.error">
                <p><strong class="text-danger">ERROR: </strong>Unable to finish deleting the site. Please see above table to see what has failed. If this issue continues, please contact an admin.</p>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeDeleteSiteModal'>Close</button>
            <submit-button v-if="deleteSite.canDelete" type="button" :background="'btn btn-danger'" @click="handleDeleteSite" text="Delete Site" :clicked="deleteSite.deleting"></submit-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import SubmitButton from '../../../components/SubmitButton.vue';
import SecureImage from '../../../components/SecureImage.vue';
// import Multiselect from '@vueform/multiselect'
import { v4 as uuidv4 } from 'uuid';
import SiteAttributes from "./SiteAttributes";
import SiteNodes from "./SiteNodes";
import Time from '../../../components/Time.vue';
import {DateTime} from 'luxon';
import SiteMap from "../../../components/SiteMap";
import AdminJobs from '../jobs/Jobs';
import CustomerJobs from '../../customer/jobs/Jobs';
import SiteStats from "./SiteStats";
import SiteFileManager from './SiteFileManager.vue';
import SiteNotes from './SiteNotes.vue';
import SiteMaintenance from './SiteMaintenance.vue';

export default {
  components: {SiteStats, SiteMap, SiteNodes, SiteAttributes, SubmitButton, SecureImage, Time, AdminJobs, SiteFileManager, CustomerJobs, SiteNotes, SiteMaintenance },
  name: 'admin.sites.view',
  props: ['id'],
  data(){
    return {
      site: {
        name: "",
        client_id: -1,
        street: "",
        town: "",
        county: "",
        postcode: "",
        telephone: "",
        telephone_verified: 0,
        range_and_subnet: null,
        client_assigned_id: null,
        brand_site_id: null,
        email: ""
      },
      edit: false,
      deleteSite: {
        checking: true,
        canDelete: false,
        reasons: [],
        error: false,
        deleting: false,
        actions: {
          documents: {
            status: 0,
            count: 0
          },
          subnets: {
            status: 0,
            count: 0
          },
          nodes: {
            status: 0,
            count: 0
          },
          nodeLogins: {
            status: 0,
            count: 0
          },
          nodePings: {
            status: 0,
            count: 0
          }
        }
      },
      clients: [],
      brands: [],
      brand: {
        id: null,
        identifier: null,
        active: false
      },
      updatingBrands: false,
      editBrands: false,
      jobsCounter: 0,
      notesCounter: 0,
      statuses: []
    }
  },
  mounted(){
    document.addEventListener('keydown', this.handleKeyDown);
    this.loadSite();
    this.loadSiteStatuses();
    this.loadClients();
    this.loadBrands();
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    updateJobsCounter(total){
      this.jobsCounter = total;
    },
    loadSite(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.id}`)
      .then(response => {
        this.site = response.data.site;
        this.site.formattedInstallDate = this.site.install_date;
        if(this.site.install_date !== null && this.site.install_date !== undefined){
          this.site.install_date = DateTime.fromSeconds(this.site.install_date).toFormat('yyyy-MM-dd');
        }
      })
      .catch(error => {
        this.$error("Failed to load site.", error);
      })
    },
    loadClients(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/list`)
          .then(response => {
            this.clients = response.data.clients;
          })
          .catch(error => {
            this.$error("Failed to load clients", error);
          })
    },
    copyAddress(){
      let address = `${this.site.street},${this.site.town},${this.site.county},${this.site.postcode}`;
      navigator.clipboard.writeText(address).then(() => {
        this.$success("Copied Address!");
      }, (err) => {
        this.$error("Failed to copy address!", err);
      });
    },
    saveChanges(){
      this.updating = true;
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/sites/${this.id}`, this.site)
      .then(() => {
        this.$success("Updated site");
        this.updating = false;
        this.edit = false;
      })
      .catch(error => {
        this.$error("Failed to update site.", error);
        this.updating = false;
      })
    },
    loadBrands(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/brands/list`)
      .then(response => {
        this.brands = response.data.brands
      })
      .catch(error => {
        this.$error("Failed to load brands", error);
      })
    },
    getUUID(){
      return uuidv4();
    },
    migrateSite(){
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/sites/${this.id}/client`, {
        client: this.site.client_id
      })
      .then(() => {
        this.$success("Migrated site");
        this.loadSite();
        $('#closeMigrateSiteClient').click();
      })
      .catch(error => {
        this.$error("Failed to migrate site!", error);
      })
    },
    addBrand(){
      this.brand.active = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/brands`, {
        brand_id: this.brand.id,
        identifier: this.brand.identifier
      })
      .then(response => {
        if(response.data.success){
          $('#closeAddBrandToSite').click();
          this.loadSite();
          this.brand.id = null;
          this.brand.identifier = null;
        }else{
          this.$warn(response.data.message || "Unable to add brand to site");
        }
      })
      .catch(error => {
        this.$error("Failed to add brand to site", error);
      })
      .finally(() => {
        this.brand.active = false;
      })
    },
    deleteBrand(id){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/brands`, {
        data: {
          brand_id: id
        }
      })
      .then((response) => {
        if(response.data.success){
          this.$success("Deleted a site brand");
          this.loadSite();
        }
      })
      .catch(error => {
        this.$error("Failed to delete site brand", error);
      })
    },
    updateBrands(){
      this.updatingBrands = true;
      axios.patch(`${process.env.VUE_APP_API_URL}/v1/sites/${this.id}/brands`, {
        brands: JSON.stringify(this.site.brands)
      })
      .then(() => {
        this.$success("Updated site brands");
        this.updatingBrands = false;
        this.editBrands = false;
      })
      .catch(error => {
        this.$error("Failed to update site brands.", error);
        this.updatingBrands = false;
      })
    },
    loadSiteStatuses(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/statuses`)
      .then(response => {
        this.statuses = response.data.statuses;
      })
      .catch(error => {
        this.$error("Failed to load site statues", error);
      })
    },
    openDeleteModal(){

      $('#deleteSiteModal').modal('show');

      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/delete`)
      .then(response => {
        if(response.data.success){
          this.deleteSite.canDelete = response.data.canDelete;
          if(response.data.reasons.length > 0){
            this.deleteSite.reasons = response.data.reasons;
            this.deleteSite.checking = false;
          }else{
            this.deleteSite.actions.documents.count = response.data.actions.documents;
            this.deleteSite.actions.subnets.count = response.data.actions.subnets;
            this.deleteSite.actions.nodes.count = response.data.actions.nodes;
            this.deleteSite.actions.nodeLogins.count = response.data.actions.nodeLogins;
            this.deleteSite.actions.nodePings.count = response.data.actions.nodePings;
            this.deleteSite.checking = false;
          }
        }
      })
      .catch(error => {
        this.$error("Failed to check if site can be deleted", error);
        this.deleteSite.canDelete = false;
        this.deleteSite.reasons.push(`Failed to check if site can be deleted.`);
        this.deleteSite.checking = false;
      });

    },
    async deleteDocuments(){
      this.deleteSite.actions.documents.status = 1;
      try{
        let response = await axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/delete/documents`);
        if(response.data.remaining > 0){
          await this.deleteDocuments();
        }
        this.deleteSite.actions.documents.status = -1;
        return true;
      }catch(err){
        this.$error("Failed to delete documents.", err);
        this.deleteSite.actions.documents.status = -2;
        return false;
      }

    },
    async deleteSubnets(){
      this.deleteSite.actions.subnets.status = 1;
      try{
        await axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/delete/subnets`);
        this.deleteSite.actions.subnets.status = -1;
        return true;
      }catch(err){
        this.$error("Failed to delete subnets.", err);
        this.deleteSite.actions.subnets.status = -2;
        return false;
      }
    },
    async deleteNodeLogins(){
      this.deleteSite.actions.nodeLogins.status = 1;
      try{
        await axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/delete/nodelogins`);
        this.deleteSite.actions.nodeLogins.status = -1;
        return true;
      }catch(err){
        this.$error("Failed to delete node logins.", err);
        this.deleteSite.actions.nodeLogins.status = -2;
        return false;
      }
    },
    async deleteNodePings(){
      this.deleteSite.actions.nodePings.status = 1;
      try{
        let response = await axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/delete/nodepings`);
        if(response.data.remaining > 0){
          this.deleteSite.actions.nodePings.count = response.data.remaining;
          await this.deleteNodePings();
        }
        this.deleteSite.actions.nodePings.count = 0;
        this.deleteSite.actions.nodePings.status = -1;
        return true;
      }catch(err){
        this.$error("Failed to delete documents.", err);
        this.deleteSite.actions.nodePings.status = -2;
        return false;
      }
    },
    async deleteNodes(){
      this.deleteSite.actions.nodes.status = 1;
      try{
        await axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/delete/nodes`);
        this.deleteSite.actions.nodes.status = -1;
        return true;
      }catch(err){
        this.$error("Failed to delete nodes.", err);
        this.deleteSite.actions.nodes.status = -2;
        return false;
      }
    },
    async handleDeleteSite(){

      this.deleteSite.deleting = true;
      
      let res = await this.deleteDocuments();
      res = res && await this.deleteSubnets();
      res = res && await this.deleteNodeLogins();
      res = res && await this.deleteNodePings();
      res = res && await this.deleteNodes();

      if(!res){
        this.deleteSite.error = true;
        this.deleteSite.deleting = false;
        return;
      }

      axios.delete(`${process.env.VUE_APP_API_URL}/v1/sites/${this.site.id}/delete`)
      .then(response => {
        if(response.data.success){
          this.$success("Site deleted");
          this.$router.push({name: 'admin.sites'});
        }
      })
      .catch(error => {
        this.$error("Failed to delete site.", error);
      })

    }
  },
  computed: {
  }
}
</script>

<style scoped>

  td {
    padding: 0 !important;
  }

  table input:not([type=checkbox]) {
    background: transparent;
    border-bottom: none;
    border-top: none;
    border-right: none;
  }

  .checkbox-td {
    text-align: center;
    vertical-align: middle;
  }

  table .form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px) !important;
  }

</style>

<style src="@vueform/multiselect/themes/default.css"></style>